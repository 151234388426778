import { registerApplication, start, LifeCycles, AppProps } from "single-spa";

// registerApplication({
//   name: "sidebar",
//   app: () =>
//     System.import<LifeCycles>(
//       "https://unpkg.com/single-spa-welcome/dist/single-spa-welcome.js"
//     ),
//   activeWhen: (location) => ["/consultivo", "/prevencao-de-pedas"].includes(location.pathname),
// });

registerApplication({
  name: "catch-all",
  app: async () => {
    // Perform the redirection only if the route is not in the valid routes list
    return {
      async bootstrap() {
        if (!isValidRoute()) {
          redirectToHome();
        }
      },
      async mount(props: AppProps) {
        if (!isValidRoute()) {
          redirectToHome();
        }
      },
      async unmount() {},
    };
  },
  activeWhen: (location) => !isValidRoute(location.pathname), // Redirect only for invalid routes
});

registerApplication({
  name: "single-spa",
  app: () =>
    System.import<LifeCycles>(
      "https://unpkg.com/single-spa-welcome/dist/single-spa-welcome.js"
    ),
  activeWhen: (location) => ["/"].includes(location.pathname),
});

registerApplication({
  name: "@mfe/components",
  app: () => System.import<LifeCycles>("@mfe/components"),
  activeWhen: (location) => ["/"].includes(location.pathname),
});

registerApplication({
  name: "@mfe/auth",
  app: () => System.import<LifeCycles>("@mfe/auth"),
  activeWhen: (location) => ["/auth", "/auth/"].includes(location.pathname),
});

registerApplication({
  name: "@mfe/consultivo",
  app: () => System.import<LifeCycles>("@mfe/consultivo"),
  activeWhen: (location) => ["/modules/consultivo", "/modules/consultivo/"].includes(location.pathname),
});

registerApplication({
  name: "@mfe/prevencao-de-perdas",
  app: () => System.import<LifeCycles>("@mfe/prevencao-de-perdas"),
  activeWhen: (location) => ["/modules/prevencao-de-perdas", "/modules/prevencao-de-perdas/"].includes(location.pathname),
});

registerApplication({
  name: "@mfe/due-diligence",
  app: () => System.import<LifeCycles>("@mfe/due-diligence"),
  activeWhen: (location) => ["/modules/due-diligence", "/modules/due-diligence/"].includes(location.pathname),
});

function isValidRoute(route = window.location.pathname) {
  const validRoutes = ["/auth", "/auth/"];
  validRoutes.push(...["/modules/consultivo", "/modules/consultivo/"])
  validRoutes.push(...["/modules/prevencao-de-perdas", "/modules/prevencao-de-perdas/"])
  validRoutes.push(...["/modules/due-diligence", "/modules/due-diligence/"])
  return validRoutes.includes(route);
}

function redirectToHome() {
  window.location.href = "/auth#/home";
}

start({
  urlRerouteOnly: true,
});
